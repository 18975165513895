/* Vertical scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust width for vertical scrollbar */
  height: 5px; /* Adjust width for vertical scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #0a9ef3;
  border-radius: 15px;
}

/* Horizontal scrollbar */
::-webkit-scrollbar-horizontal {
  height: 5px; /* Adjust height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb:horizontal {
  background: #0a9ef3;
}

@media print {
  iframe {
    display: none;
  }
}
