.sales-grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;
  }
  .sales-grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid #F5F5F5;
    padding: 10px;
    font-size: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  }
  
  
  .no-border-and-shadow{
    box-shadow:none;
    border:none;
  }
  .give-10px-border-radious{
    border-radius: 10px;
  }
  
  .truncated-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  
  .truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Adjust this value as needed */
    display: inline-block;
    vertical-align: middle;
  }
  
  .truncated-container:hover .full-text {
    display: block;
  }
  
  .full-text {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    padding: 5px;
    z-index: 1000;
    white-space: normal;
    max-width: 700px; /* Adjust as needed */
    word-wrap: break-word;
    font-size: small;
  }
  
  